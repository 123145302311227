<template>
  <div class="modal modal_wrap">
    <div class="modal_popup modal_ligthBg">
      <div class="modal_content">
        <h2 class="titlePage titlePage_textLeft">Products Total</h2>

        <Form class="form" v-slot="{ errors }">

          <div class="d-flex justify-content-between align-items-center">
            <h3 class="text text_center"
                :class="{active: standard}"
                @click="standard = true"
            >Margin</h3>
            <h3 class="text text_center"
                :class="{active: !standard}"
                @click="standard = false"
            >Details</h3></div>

          <transition name="slide" mode="out-in">
            <div v-if="standard">
              <div class="row align-items-center">
                <div class="col-6">
                  <span class="form__labelTitle">Margin</span>
                </div>
                <div class="col-6">
                  <label class="form__label form__label_row">
                    <Field type="text"
                           class="form__input form__input_m100"
                           name="margin"
                           :rules="'required|between:0, 200'"
                           v-model="totalPrice.margin"
                           @change="changeMargin"
                    />
                    <span class="form__labelTitle">%</span>
                  </label>
                  <span class="form__error">{{ errors.margin }}</span>
                </div>
              </div>
            </div>

            <div v-else>
              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Product cost:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">$ {{totalPrice.product_cost}}</p>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Charges:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">$ {{totalPrice.charges}} </p>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Total cost:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">$ {{totalPrice.total_cost}}</p>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Margin:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">{{totalPrice.margin}} %</p>
                </div>
              </div>

              <div class="row align-items-center" v-if="totalPrice.discount && totalPrice.discount != 0">
                <div class="col-6">
                  <p class="text text_default">Discount:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">$ {{totalPrice.discount}}</p>
                </div>
              </div>


              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Price:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">$ {{totalPrice.price}}</p>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Net profit:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default" v-bind:class="{'text_red' : totalPrice.net_profit_aud < 0}">$ {{totalPrice.net_profit_aud}}</p>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Net profit:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">{{totalPrice.net_profit_pct}} %</p>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-6">
                  <p class="text text_default">Rate per hour:</p>
                </div>
                <div class="col-6">
                  <p class="text text_default">$ {{totalPrice.rate_per_hour}}</p>
                </div>
              </div>
            </div>
          </transition>

        </Form>

        <div class="modal__footer modal__footer_borderTop">
          <p class="text">Order Total: <span>$ {{totalPrice.order_total}}</span></p>
        </div>
      </div>
      <button type="button" class="btn btn_transparent" @click="close">
        Done
      </button>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import router from "../../router";

  export default {
    name: "TotalPriceWindow",

    data() {
      return {
        standard: true,
      }
    },

    methods: {
      close() {
        this.$store.commit('orderPreview/setTotalPriceWindow', false);
      },

      changeMargin() {
       /* this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('totalPriceOrder/putMargin');
        /*  }
        });*/
      },
        refactorPrice(price){
            return changePriceForm(price)
        }
    },

    computed: {
      ...mapState('totalPriceOrder',
        ['totalPrice']
      ),
    },

    created() {
      this.$store.dispatch('totalPriceOrder/getTotalPrice');
    }

  }
</script>

<style scoped lang="scss">

  @import "../../../src/assets/scss/utils/vars";

  h3.text {
    margin: 0 0 20px;
    border-bottom: 2px solid #B5B5B5;
    cursor: pointer;
    &.active {
      border-bottom: 2px solid $navy;
    }
  }

  .form {
    .row {
      margin-bottom: 10px;
      .text_default {
        margin: 0;
      }
    }
    &__input {
      margin-right: 5px;
    }
    &__label {
      &_row {
        margin-right: 0;
      }
    }
  }

  .modal {
    &_popup {
      padding: 2.5rem 2rem;
    }

    &_content {
      padding: 0 10px;
    }

    &__close {
      top: 10px;
    }

    .titlePage {
      color: $navy;
    }
  }

  .btn.btn_transparent{
    margin-bottom: 0;
  }
</style>
